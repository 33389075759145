@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');


.EditOrder {
    opacity: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-30px);
    transition: opacity 0.2s ease-in, visibility 0.2s ease-in, transform 0.2s ease-in;
    visibility: hidden;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: rgba(0, 0, 0, 0.699);
    cursor: pointer;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 102%;
    width: 100%;
}

.EditOrder.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.EditOrderC {
    position: relative;
    background: #fff;
    max-width: 1000px;
    padding: 25px 40px 10px 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: default;
    max-height: 90%;
}

.EditOrder form {
    padding: 30px 0 0 0;
}

.EditOrder form .form-row {
    display: flex;
    margin: 32px 0;
}

form .form-row .input-data,
form .form-row .input-checkbox {
    width: 100%;
    height: 40px;
    margin: 0 20px;
    position: relative;
}

form .form-row .textarea {
    height: 70px;
}

.input-checkbox label {
    font-size: 16px;
    color: #037525;
    font-weight: 600;
}

input.checkbox-style {
    width: 30px !important;
    height: 20px;
}

input.checkbox-style:checked {
    accent-color: #25d459 !important;
}


.input-data input,
.textarea textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 17px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.larger {
    width: 50px !important;
    height: 40px !important;
}

.input-data input:focus~label,
.textarea textarea:focus~label,
.input-data input:valid~label,
.textarea textarea:valid~label {
    transform: translateY(-20px);
    font-size: 16px;
    font-weight: 600;
    color: #037525;
}

.textarea textarea {
    resize: none;
    padding-top: 10px;
}

.input-data label {
    position: absolute;
    width: 100%;
    pointer-events: none;
    left: 0;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.textarea label {
    width: 100%;
    bottom: 40px;
    background: #fff;
}

.input-data .underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    left: 0;
}

.input-data .underline:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    left: 0;
    background: #00dd42;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.input-data input:focus~.underline:before,
.input-data input:valid~.underline:before,
.textarea textarea:focus~.underline:before,
.textarea textarea:valid~.underline:before {
    transform: scale(1);
}

.submit-btn .input-data {
    overflow: hidden;
    height: 45px !important;
    width: 25% !important;
}

.submit-btn .input-data .inner {
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
    transition: all 0.4s;
}

.submit-btn .input-data:hover .inner {
    left: 0;
}

.submit-btn .input-data input {
    background: none;
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

@media (max-width: 700px) {
    .EditOrder .text {
        font-size: 30px;
    }

    .EditOrder form {
        padding: 10px 0 0 0;
    }

    .EditOrder form .form-row {
        display: block;
    }

    form .form-row .input-data {
        margin: 35px 0 !important;
    }

    .submit-btn .input-data {
        width: 40% !important;
    }
}

@media (max-height: 880px) {
    .EditOrderC {
        overflow: auto;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    font-size: 17px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 1;
}

.react-datepicker__input-container input:focus~label,
.react-datepicker__input-container input:valid~label,
.react-datepicker__input-container input.focus~label,
.react-datepicker__input-container input.valid~label {
    transform: translateY(-20px);
    font-size: 14px;
    color: #3498db;
}


/* Override react-datepicker styles */
.react-datepicker-wrapper {
    width: 100%;
    position: relative;
}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    font-size: 17px;
    border-bottom: 2px solid #00dd42;
}

.react-datepicker__input-container input:focus~label,
.react-datepicker__input-container input:not(:placeholder-shown)~label {
    transform: translateY(-20px);
    font-size: 14px;
    color: #3498db;
}

.date-label {
    position: absolute;
    width: 100%;
    pointer-events: none;
    left: 0;
    bottom: 30px;
    font-size: 16px;
    color: #037525;
    font-weight: 600;
    z-index: 2;
    transition: all 0.3s ease;
}

.react-datepicker-popper {
    z-index: 1000;
}

.hidden {
    display: none;
}

.padding-row {
    height: 40px;
}

.select-style {
    display: block;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    font-size: 17px;
    border-bottom: 2px solid #00dd42;
    position: relative;
    z-index: 1;
}

.select-style:focus~label,
.select-style:valid~label {
    transform: translateY(-20px);
    font-size: 16px;
    font-weight: 600;
    color: #037525;
    z-index: 2;
}

.select-style label {
    position: absolute;
    width: 100%;
    pointer-events: none;
    left: 0;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.select-style option {
    text-align: center;
}

option:nth-child(1):hover {
    background: #037525 !important;
}

.delete-button {
    background-image: linear-gradient(-180deg, #ff8b8b 0%, #ff0000 74%);
    border-color: #ff0000;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue",
        Arial, sans-serif;
    outline: 0;
    height: 30px;
    color: white;
    border: 0;
}

.EditOrderC i {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 26px;
}

.EditOrderC i:hover {
    color: #037525;
}

.error-message {
    color: red;
    font-size: 20px;
    font-weight: 600;
}