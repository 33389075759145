@import url("https://fonts.googleapis.com/css2?family=Cabin:ital@1&family=Karantina&family=Poppins:wght@300&display=swap");

html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100%;
	text-align: center;
	color: black;
	font-family: 'Poppins', sans-serif;
	/* overflow: hidden; */
	background-color: #aad4a8;
	background-image: url("./Assets/Images/backgroundtest.png");
	background-size: cover;
	/* Ensures the image covers the entire screen */
	background-position: center;
	/* Centers the image */
	background-repeat: no-repeat;
	/* Prevents the image from repeating */
	background-attachment: fixed;
	/* Keeps the image fixed on scroll */
}


a {
	color: rgb(93, 157, 252);
}

a:hover {
	color: #408af8;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #c7c7c7;
}

::-webkit-scrollbar {
	width: 10px;
	background-color: #949494;
}

::-webkit-scrollbar-thumb {
	background: rgb(17, 180, 39);
	background: linear-gradient(0deg,
			rgb(40, 100, 34) 0%,
			rgb(151, 233, 144) 100%);
}

.button-29 {
	align-items: center;
	appearance: none;
	background-image: radial-gradient(100% 100% at 100% 0,
			#a3ff8c 0,
			#3a9124 100%);
	border: 0;
	box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
		rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
		rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	height: 50px;
	justify-content: center;
	padding-left: 16px;
	padding-right: 16px;
	transition: box-shadow 0.15s, transform 0.15s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	will-change: box-shadow, transform;
	font-size: 18px;
	transform: translateY(-2px);
}

.button-29:hover {
	box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
		rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #4db832 0 -3px 0 inset;
	transform: translateY(-4px);
	color: #ffffff;
}

form input {
	text-align: center;
}

.card-header {
	font-size: 20px;
	font-weight: bold;
}