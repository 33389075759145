.Login {
    margin: auto;
    justify-content: center;
    align-items: center;
}


.Login>form {
    width: 350px;
    margin: 0 auto;
}

.Login>h2 {
    text-align: center;
}

.Login input,
.Login button {
    width: 100%;
    margin-bottom: 20px;
}

.Login span {
    color: red;
    /* margin: 0 20px; */
    /* position: absolute; */
    /* Will stay exactly where it is. */
}