.Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;

}

.Box {
    display: inline-block;
    border: 1px solid rgb(49, 134, 0);
    border-radius: 10px;
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.75);
    text-align: center;
    background-color: rgba(214, 255, 226, 0.527);
    width: 100%;
    padding: 20px;
    transition: 0.3s ease-in-out;
    min-height: 650px;
}

.TasksContent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Show up to 7 columns on larger screens */
    gap: 20px;
    /* Space between grid items */
    padding: 10px;
}

.OrdersContent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Show up to 7 columns on larger screens */
    gap: 20px;
    /* Space between grid items */
    padding: 10px;
}

/* For responsiveness, add media queries */
@media (max-width: 1200px) {
    .TasksContent {
        grid-template-columns: repeat(4, 1fr);
        /* 4 columns on medium screens */
    }

    .OrdersContent {
        grid-template-columns: repeat(4, 1fr);
        /* 4 columns on medium screens */
    }
}

@media (max-width: 900px) {
    .TasksContent {
        grid-template-columns: repeat(3, 1fr);
        /* 3 columns on small screens */
    }

    .OrdersContent {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 600px) {
    .TasksContent {
        grid-template-columns: repeat(1, 1fr);
        /* 1 column on very small screens */
    }

    .OrdersContent {
        grid-template-columns: repeat(1, 1fr);
    }
}

.TasksContent .button-29 {
    height: 30px;
    width: 100%;
}

.readyNotPaid {
    border: #e69dba 5px solid;
}

.card-text {
    font-size: 20px;
}

.readyNotPaid .button-29 {
    background-image: radial-gradient(100% 100% at 100% 0,
            #f5c0ff 0,
            #e69dba 100%);
}

.readyNotPaid .button-29:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #cc2af0 0 -3px 0 inset;
    transform: translateY(-4px);
    color: #ffffff;
}