.ProfileDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.ProfileDetails .card {
	grid-column: span 4;
	overflow-x: hidden;
	padding: 0;
	min-height: 400px;
	min-width: 400px;
	background-color: rgba(0, 0, 0, 0);
}

.ProfileDetails .card-footer {
	font-size: x-large;
	border-top: 1px solid black;
	background-color: rgb(220, 231, 245);
}

.ProfileDetails .card-header {
	font-size: xx-large;
	border-bottom: 1px solid black;
	background-color: rgb(220, 231, 245);
}

.ProfileDetails .card-body {
	font-size: x-large;
	background-color: rgba(218, 231, 255, 0.63);
}

.ProfileDetails .main-table {
	margin: 50px;
	margin-top: 100px;
}

#profile tr,
th,
td {
	width: 300px;
	font-size: 110%;
}

#profile td:nth-child(odd) {
	text-align: start;
}

.ProfileDetails .foot-table {
	height: 20px;
	margin-top: 42px;
}

.ProfileDetails .foot-table>tfoot>tr>td {
	width: 180px;
}


.logoutButton {
	text-decoration: none;
	color: red;
}

.logoutButton:hover {
	color: red;
	opacity: 50%;
}