.navbar-header {
    display: flex;
    height: 105px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    position: fixed;
    border-bottom: 1px solid rgb(179, 179, 179);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.216);
}

#logo-pic {
    cursor: pointer;
    width: 75px;
    height: 75p;
    margin-bottom: 15px;
}

#navcontainer {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}

.authMenu {
    margin-right: 250px;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding: 10px 20px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    padding: 5px;
    cursor: pointer;
}

#mobile {
    display: none;
}

#mobile i {
    color: #000000;
    padding-right: 10px;
}

.fa-house {
    display: none;
}

/* ----------------------------------------------------------- */
/* When the screen is small */
@media screen and (max-width: 1300px) {
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        top: 105px;
        right: -200px;
        width: 200px;
        height: calc(100% - 105px);
        background: rgb(255, 255, 255);
        border: 1px solid rgb(0, 0, 0);
        padding: 0 0 0 10px;
        transition: 0.5s ease-in-out;
        overflow: auto;
    }

    #navbar::-webkit-scrollbar {
        display: none;
    }

    #navbar.active {
        right: 0;
        box-shadow: 0px 5px 30px 0px rgb(0, 0, 0);
    }

    .authMenu {
        margin-right: 0px;
    }

    #mobile {
        display: block;
        width: 32px;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

    .Box {
        width: 100% !important;
        transition: 0.3s ease-in-out;
    }

    /* icons */
    .fa-house {
        display: block;
        margin-top: 9px;
        font-size: 20px;
    }
}

/* ----------------------------------------------------------- */

/* Link hover effects */
#navbar li:after,
#navbar li:before {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0);
    content: " ";
    display: block;
    margin: 0 auto;
    position: relative;
    transition: all 280ms ease-in-out;
    width: 0;
}

#navbar li:hover:after,
#navbar li:hover:before,
#navbar li.active:after,
#navbar li.active:before {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-color: #67e660;
    transition: width 350ms ease-in-out;
    width: 100%;
}

.showorderbutton {
    display: contents;
    text-decoration: none;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
}