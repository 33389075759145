/* Ensure the container of the textarea is positioned relatively */
.textarea {
    position: relative;
    height: 125px !important;
    width: 400px;
}

.textarea textarea {
    padding-top: 30px;
    text-align: center;
    color: white;
    font-size: 18px;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

/* Set the initial position of the textarea label to be above the textarea */
.card-header label {
    left: 0;
    top: -5px;
    /* Position the label above the textarea initially */
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    pointer-events: none;
    transition: all 0.3s ease;
    background-color: #03752500;
}

.card-header {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

/* Move the label when the textarea is focused or contains text */
.textarea textarea:focus~label,
.textarea textarea:valid~label {
    transform: translateY(0);
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
}

.AddTask .button-29 {
    width: 100%;
}

@media (max-width: 600px) {
    .textarea {
        width: 312px;
    }
}