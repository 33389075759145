.EditProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.EditProfile>form {
    min-width: 200px;
    align-items: center;
    text-align: center;
}

.EditProfile span {
    color: red;
}

.EditProfile select {
    width: 70px;
    margin-left: auto;
    margin-right: auto;
}

.select-input>label {
    text-align: center;
}

.EditProfile table {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}