.tables-wrapper {
    display: flex;
    flex-wrap: wrap;
    /* Allow tables to wrap if they don't fit */
    gap: 1rem;
    /* Space between tables */
    justify-content: flex-start;
    /* Align tables to the start (left) */
    align-items: flex-start;
    /* Align the tops of the tables */
}

.table-container {
    flex: 1 1 auto;
    /* Allow the table to shrink and grow */
    max-width: 100%;
    /* Ensure table doesn't exceed the container's width */
    overflow-x: auto;
    /* Allow horizontal scrolling if needed */
    min-width: 300px;
    /* Prevent the table from getting too small */
    box-sizing: border-box;
    /* Include padding and borders in width */
    margin-bottom: 1.5rem;
    /* Spacing at the bottom */
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    font-size: 1rem;
}

.orders-table th,
.orders-table td {
    padding: 0.4rem;
    text-align: center;
    white-space: nowrap;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

.orders-table th {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.orders-table td.amountCol {
    color: green;
    text-align: center;
}

.orders-table tbody tr {
    background-color: #f9f9f9;
}

.orders-table tbody tr:hover {
    background-color: #f1f1f1;
}

.orders-table .fa-info-circle {
    color: #059c12;
    font-size: 20px;
    cursor: pointer;
}

.orders-table .fa-info-circle:hover {
    color: #056e0e;
}

.ready_line {
    background-color: #deffe3 !important;
}

.not_ready_line {
    background-color: #fbfffc !important;
}

.ready_not_paid {
    background-color: #ffd5d5 !important;
}

.urgentOrders td {
    background-color: #ffd5d5 !important;
}

.orderDescription {
    width: 400px !important;
    max-width: 400px !important;
    min-width: 400px !important;
    overflow-x: auto;
    white-space: nowrap;
}

.statusCol {
    width: 110px !important;
    max-width: 110px !important;
    min-width: 110px !important;
    overflow-x: auto;
    white-space: nowrap;
}

.orderDescriptionDiv {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    padding: 5px;
}

.statusColDiv {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    padding: 5px;
}

.customerNameDiv {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.branchHeader {
    font-size: 1.4rem;
}

@media (max-width: 800px) {

    .orderDescription,
    .pickupDate,
    .amountCol,
    .customerName {
        display: none;
    }

    /* tr {
        display: flex;
        justify-content: space-between;
    } */
}

#search-order-label {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0 15px 15px;
}

#search-order {
    width: 270px;
    height: 40px;
    text-align: center;
    font-size: 1.2rem;
}